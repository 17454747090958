import GATSBY_COMPILED_MDX from "/opt/build/repo/src/mdx-pages/treaties-for-torontonians/treaty-of-niagara/pontiac-war.mdx";
import React from "react";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import HomeButton from "../components/HomeButton";
import BkuBreadcrumbs from "../components/bku-Breadcrumbs";
import {Breadcrumb} from "gatsby-plugin-breadcrumb";
import Activity from "../components/Activity";
import ActivitiesIndex from "../components/ActivitiesIndex";
import Callout from "../components/Callout";
import Menu from "../components/Menu";
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Footer from "../components/Footer";
import {graphql, Link, useStaticQuery} from "gatsby";
import HeaderBar from "../components/HeaderBar";
import NextPrev from "../components/NextPrev";
import {MDXProvider} from "@mdx-js/react";
import * as PropTypes from "prop-types";
import {GatsbyImage, StaticImage, getImage} from "gatsby-plugin-image";
import RandomBeaver from "../components/RandomBeaver";
import Beaver from "../components/Beaver";
import Video from "../components/Video";
import {Helmet} from "react-helmet";
const styles = {
  pageStyles: css`
    color: #000000;
    padding: 0;
    font-family: georgia, serif;
    line-height: 150%;
    font-size: 20px;
  `,
  h1: css`
    text-align: center;
    padding-top: 10rem;
    padding-bottom: 3rem;
    font-size: 5.9vw;
    font-family: "AvantGardeCondensed", georgia, serif;
    line-height: normal;
    @media (max-width: 415px) {
      font-size: 3rem;
      padding-top: 5rem;
    }
  `,
  container: css`
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 5rem;
  `,
  blogPost: css`
    h2,
    h3 {
      text-align: center;
      margin-bottom: 1rem;
    }
    p {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      line-height: 3rem;
    }
    ul {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
    }
    blockquote {
      max-width: 450px;
      background-color: cornflowerblue;
      padding: 10px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
    }
    blockquote > p {
      margin: 0;
      padding: 0;
    }
    .toom {
      margin: 0 auto 0 auto;
    }
  `
};
const blogPost = ({textColor}) => css`
  h2,
  h3 {
    text-align: center;
    margin-bottom: 1rem;
  }
  p {
    color: ${textColor};
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    line-height: 3rem;
  }
  ul {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
  blockquote {
    max-width: 450px;
    background-color: cornflowerblue;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
  blockquote > p {
    margin: 0;
    padding: 0;
  }
  .toom {
    margin: 0 auto 0 auto;
  }
`;
function MDXRenderer(props) {
  return null;
}
function PostTemplate({data, children, pageContext}) {
  const {frontmatter, body} = data.mdx;
  const {breadcrumb: {crumbs}} = pageContext;
  const shortcodes = {
    Activity,
    ActivitiesIndex,
    Callout,
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    Link,
    RandomBeaver,
    Beaver,
    Video
  };
  const pagesInOrder = data.allMdx.nodes;
  const currentPageIndex = pagesInOrder.findIndex(o => o.id === data.mdx.id);
  const next = currentPageIndex + 1 > pagesInOrder.length ? null : pagesInOrder[currentPageIndex + 1];
  const prev = currentPageIndex - 1 < 0 ? null : pagesInOrder[currentPageIndex - 1];
  return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("title", null, frontmatter.title, " | Talking Treaties")), React.createElement("main", {
    css: styles.pageStyles
  }, React.createElement(HeaderBar), React.createElement("div", {
    className: "blog-post-container",
    css: styles.container
  }, React.createElement("div", {
    className: "blog-post-body-container"
  }, frontmatter.headerImage ? React.createElement(React.Fragment, null, React.createElement(GatsbyImage, {
    image: getImage(frontmatter.headerImage),
    alt: "header image",
    layout: "fixed",
    style: {
      maxWidth: "800px",
      margin: "2rem auto 0 auto",
      display: "block"
    }
  }), React.createElement("h1", {
    style: {
      marginTop: "3rem"
    }
  }, frontmatter.title)) : React.createElement("h1", {
    css: styles.h1
  }, frontmatter.title), React.createElement("div", {
    css: styles.blogPost
  }), React.createElement(MDXProvider, {
    components: shortcodes
  }, children))), next !== "" && prev !== "" && currentPageIndex !== pagesInOrder.length - 1 && React.createElement(NextPrev, {
    next: next,
    prev: prev
  }), React.createElement(Footer), React.createElement(Menu)));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PostTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query ($id: String!) {
    allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { frontmatter: { status: { eq: "active" } } }
    ) {
      nodes {
        id
        frontmatter {
          slug
          status
          title
          order
        }
      }
    }
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        status
        order
        #        headerImage {
        #          childImageSharp {
        #            gatsbyImageData
        #          }
        #        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
